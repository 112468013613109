





































































import Vue from 'vue'

export default Vue.extend({
  name: "PreScreeningQuestions",
  props: {
    questionSet: {
      type: String,
      required: true,
      validator(value: string) {
        return ['None', 'DayShift', 'NightShift'].includes(value)
      }
    },
    toggled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      answers: [] as string[],
      turnAwayApplicant: false,
    }
  },
  computed: {
    questions (): string[] {
      let questions = [] as string[]
      let dayShiftQuestions = [
        'Can you continually lift for several hours?',
        'Can you work overtime?',
        'Can you pass a background check, drug test (including marijuana), and physical?'
      ]
      let nightShiftQuestions = [
        'Can you work nights?',
        'Can you work weekends?'
      ]

      let dayShiftQuestionsSpanish = [
        '¿Puede levantar objetos continuamente por varias horas?',
        '¿Puede trabajar horas extra?',
        '¿Puede pasar un chequeo de antecedentes, prueba de drogas (incluyendo marihuana), y un examen físico?'
      ]
      let nightShiftQuestionsSpanish = [
        '¿Puede trabajar durante la noche?',
        '¿Puede trabajar durante el fin de semana?'
      ]

      if (this.questionSet === "DayShift") {
        questions = this.toggled ? dayShiftQuestionsSpanish : dayShiftQuestions
      } else if (this.questionSet === "NightShift") {
        questions = this.toggled ? dayShiftQuestionsSpanish : dayShiftQuestions
        if (this.toggled) {
          questions.splice(1, 0, ...nightShiftQuestionsSpanish)
        } else {
          questions.splice(1, 0, ...nightShiftQuestions)
        }
      }
      return questions
    },
    passed () : boolean {
      return this.answers.every(v => v === "Yes")
    },
    shouldDisableApply () : boolean {
      return this.answers.length === 0 || this.answers.some(v => !v)
    }
  },
  watch: {
    questions (newQuestions: string[], oldQuestions: string[]) {
      if (newQuestions.length !== oldQuestions.length) {
        this.resetAnswers()
      }
    }
  },
  methods: {
    submitAnswers(successCallback: () => void) {
      if (this.passed) {
        this.$emit('success', { questions: this.questions, answers: this.answers })
        successCallback()

      } else {
        this.turnAwayApplicant = true
        this.$emit('fail', { questions: this.questions, answers: this.answers })

      }
    },
    resetAnswers () {
      this.answers = Array(this.questions.length).fill('')
    }
  }
})
