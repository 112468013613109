import moment from 'moment'

export default {
  filters: {
    formatPostedDate (date: string) : string {
      const d = moment(date)
      const weekday = d.format('dddd')
      const day = d.format('MMMM Do YYYY')
      return `${weekday}, ${day}`
    },
    formatPostedDateShort (date: string) : string {
      const d = moment(date)
      return `${d.format('MM/DD/YYYY')}`
    },
  }
}