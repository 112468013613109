









































































































import Vue from 'vue'
import { Job } from '@/types'
import axios from 'axios'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import RelatedJobs from '@/components/RelatedJobs.vue'
import PreScreeningQuestions from '@/components/PreScreeningQuestions.vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import $ from 'jquery'

export default Vue.extend({
  name: 'JobDetails',
  components: {
    Header,
    Footer,
    RelatedJobs,
    PreScreeningQuestions
  },
  data () {
    return {
      loading: false,
      job: null as null | Job,
      applyResumeLink: '',
      applyLink: '',
      quickApplyLink: '',
      webHistory: '',
      toggled: false,
    }
  },
  async created () {
    this.loading = true
    this.toggled = this.$store.state.languageToggled
    this.setWebHistory()
    await this.getJob()
    await this.getApplyLinks()
    this.loading = false
    await this.postDetailsPageLog()
  },
  computed: {
    questionSet(): string {
      if (!this.job) {
        return "None"
      } else {
        return this.job.preScreeningQuestions
      }
    }
  },
  methods: {
    async getJob () {
      let r = await axios.get(`/api/jobs/latest-open?jobId=${this.$route.params.id}`)
      if (r.status == 200) {
        let job = r.data
        if (job && job.id !== this.$route.params.id) {
          this.$router.push({name: 'JobDetails', params: {id: job.id}})
        }
        this.job = job
      }
    },
    returnToJobSearch () {
      this.$router.push({name: 'JobSearch'})
    },
    handleApply() {
      const questions = this.job?.preScreeningQuestions || "None"
      if (questions !== "None") {
        this.$bvModal.show('preScreeningQuestionsModal')
      } else {
        if (this.job) {
          this.apply(this.job)
        }
      }
    },
    async handlePrescreeningSuccess (event : { questions: string[], answers: string[] }) {
      if (this.job) {
        this.apply(this.job)
      }
      await this.logPrescreeningAttempt(event.questions, event.answers, "pass")
    },
    async handlePrescreeningFailure (event : { questions: string[], answers: string[] }) {
      await this.logPrescreeningAttempt(event.questions, event.answers, "fail")
    },
    async logPrescreeningAttempt (questions: string[], answers: string[], result: string) : Promise<void> {
      const jobPostingId = this.$route.params.id
      const data = {
        jobPostingId,
        questions,
        answers,
        result
      }
      await axios.post("/api/Logs/prescreening-questions-log", data)
    },
    apply (job: Job) {
      if (job.isResume) {
        window.location.href = this.applyResumeLink
      } else if (job.quickApply) {
        window.location.href = this.quickApplyLink
      } else {
        window.location.href = this.applyLink
      }
    },
    setWebHistory () {
      let referrer = document.referrer
      let origins = window.location.ancestorOrigins
      let parent = window.parent.origin
      let current = window.origin
      // Leave console logs to test in prod
      console.log('Job Details')
      console.log(referrer)
      console.log(origins)
      console.log(parent)
      console.log(current)
      if (referrer) {
        this.webHistory = referrer
      } else if (origins && origins.length) {        
        this.webHistory = origins[origins.length - 1]
      } else if (parent && parent !== current) {         
        this.webHistory = parent
      } 
    },
    async getApplyLinks () {
      let r = await axios.get(`/api/Resources/apply-links?jobId=${this.$route.params.id}&webHistory=${this.webHistory}`)
      if (r.status == 200) {
        this.applyLink = r.data[0]
        this.applyResumeLink = r.data[1]
        this.quickApplyLink = r.data[2]
      }
    },
    getBusinessAreaDescription (id: string) {
      let area = this.$store.getters.getBusinessArea(id)
      return area ? area.description : ''
    },
    getDepartmentDescription (id: string) {
      let dep = this.$store.getters.getDepartment(id)
      return dep ? dep.description : ''
    },
    getLocationAddress (code: string) {
      let loc = this.$store.getters.getLocation(code)
      return loc ? loc.address : ''
    },
    async postDetailsPageLog () {
      let data = {
        webHistory: this.webHistory,
        jobId: this.$route.params.id,
      }
      await axios.post('/api/logs/details-page-log', data)
    },
    formatLongDescription (str: string) {
      let elems = $('<div>').append(str).find('*')
      for (let i = 0; i < elems.length; i++) {
        elems[i].style.removeProperty('font-family')
        elems[i].style.removeProperty('color')
      }
      return elems.end().html()
    },
  },
})
