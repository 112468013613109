





























import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'Header',
  methods: {
    goToLabattWebsite () {
      window.location.href = 'https://labattfood.com'
    },
    async goToOnlineApp () {
      let r = await axios.get('/api/Resources/online-app-link')
      if (r.status == 200) {
        window.location.href = r.data
      }
    },
  },
})
